<template>
  <div class="exam">
    <MyBreadcrumb></MyBreadcrumb>
    <div class="exam-head">
      <div class="exam-name">{{ examInfo.examName }}</div>
      <div class="exam-right">
        <div class="exam-time">
          考试剩余结束时间：
          <el-statistic :value="examInfo.time_countdown" time-indices format="HH:mm:ss" />
        </div>
        <div class="yy-btn">
          <el-switch style="margin-right: 20px;" v-model="listShowStatus" active-text="实时视频" inactive-text="视频截图"
            @change="screenshotVideoSwitch">
          </el-switch>
          <el-button class="quanti-yuyin" style="background-color: #333; border-color: #333; color: white;" size="small"
            @mousedown.native="voiceReminder(2)">全体语音提示</el-button>
        </div>
      </div>
    </div>
    <div class="exam-main">
      <div class="main-top">
        <div class="tabs">
          <div class="tab" :class="activeTab === '1' ? 'tab-active' : ''" @click="changeTab('1')">答题中</div>
          <div class="tab" :class="activeTab === '2' ? 'tab-active' : ''" @click="changeTab('2')">已交卷</div>
        </div>
      </div>
      <div class="videos">
        <div class="video-item" v-for="item in userList" :key="item.user.id">
          <div v-if="listShowStatus" class="user-video-wrap">
            <video autoplay playsinline :id="item.user.id"></video>
            <div class="abnormal" v-if="item?.live_room_user?.act_type_count !== '0'">
              <div class="el-icon-warning"></div>
              <div>检测到异常{{ item?.live_room_user?.act_type_count }}次</div>
            </div>
          </div>
          <div v-else class="user-video-wrap">
            <img :src="item?.live_room_user?.last_monitor_img" alt="">
            <div class="abnormal" v-if="item?.live_room_user?.act_type_count !== '0'">
              <div class="el-icon-warning"></div>
              <div>检测到异常{{ item?.live_room_user?.act_type_count }}次</div>
            </div>
          </div>
          <div class="user-info">
            <div class="user-name">{{ item.user.nickname || item.user.realname }}的答题</div>
            <div class="user-btns">
              <el-button v-if="item.status === '1'"
                style="background-color: #333333; color: white; border: 1px solid #333333;" size="small"
                @click="compulsorySubmissionPapers(item?.user?.id)">强制交卷</el-button>
              <el-button type="primary" size="small" @click="viewDetail(item)">查看详情</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-drawer :visible.sync='drawerShow' :size="940" destroy-on-close @close="drawerClose()">
      <div class='drawer-title' slot='title'>
        <div class='drawer-title-text'>
          <el-button size="small" v-if="drawerData.train_user.status === '1'" type="danger"
            @click="compulsorySubmissionPapers(drawerData?.user?.id)">强制交卷</el-button>
          <el-button class="yuyin-btn" size="small" type="primary">
            <div class="flex-btn" @mousedown="voiceReminder(1)">
              语音提醒
              <img src="../../assets/images/icon/laba.png" alt="">
            </div>
          </el-button>
          <el-button v-if="drawerData.train_user.is_cheat === '1'" size="small" style="background: #F6F7FA;" disabled>
            <div class="flex-btn">
              已标记作弊
              <img src="../../assets/images/icon/shandian.png" alt="">
            </div>
          </el-button>
          <el-button v-else size="small" type="primary" @click="markCheating()">标记作弊</el-button>
          <el-button size="small" type="primary" @click="messageReminder()">警告</el-button>
        </div>
        <div class='drawer-title-btns'>
        </div>
      </div>
      <div style='padding:20px;'>
        <div class="detail-drawer">
          <div class="user-basic-info">
            <div class="avatar">
              <img :src="drawerData.user.avatar" alt="">
            </div>
            <div class="id-class-phone">
              <div class="basic-user-name">{{ drawerData.user.nickname || drawerData.user.realname }}</div>
              <div class="spans">
                <span>ID:{{ drawerData.user.id }}</span>
                <span>所在班级:{{ drawerData.user_group_list.reduce((pre, cur) => pre += cur.name, "") }}</span>
                <span>联系方式:{{ drawerData.mobile }}</span>
              </div>
            </div>
          </div>
          <div class="the-title">画面监控</div>
          <div class="detail-video">
            <div class="video-wrap">
              <video id="remoteCameraVideo" autoplay playsinline></video>
              <div class="tips">答题实时摄像头画面</div>
            </div>
            <div class="video-wrap">
              <video id="remoteScreenVideo" autoplay playsinline></video>
              <div class="tips">答题过程监控 <el-button class="el-icon-full-screen" type="primary" size="small"
                  @click="fullscreen()">详情</el-button> </div>
            </div>
          </div>
          <div class="the-title">答题活动记录</div>
          <div class="answer-record">
            <el-timeline>
              <el-timeline-item v-if="drawerData?.train_user?.is_force_submit === '1'" color="#409EFF">
                <div class="timeline-item-flex">
                  <div class="tit">强制交卷</div>
                  <div class="cont">时间：{{ drawerData?.train_user?.finish_time && utils.timeFormatter(new
                    Date(+drawerData.train_user.finish_time * 1000), 'yyyy.MM.dd hh:mm:ss') }}</div>
                </div>
              </el-timeline-item>
              <el-timeline-item v-if="drawerData?.train_user?.status === '2'"
                :color="drawerData?.train_user?.is_force_submit === '1' ? '' : '#409EFF'">
                <div class="timeline-item-flex">
                  <div class="tit">交卷</div>
                  <div class="cont">时间：{{ drawerData?.train_user?.finish_time && utils.timeFormatter(new
                    Date(+drawerData.train_user.finish_time * 1000), 'yyyy.MM.dd hh:mm:ss') }}</div>
                  <div class="cont">答题时长：{{ drawerData?.train_user?.use_time &&
                    utils.secondsToMS(drawerData?.train_user?.use_time) }}</div>
                </div>
              </el-timeline-item>
              <el-timeline-item>
                <div class="timeline-item-flex">
                  <div class="tit">开始答卷</div>
                  <div class="cont">时间：{{ drawerData?.train_user?.start_time && utils.timeFormatter(new
                    Date(+drawerData.train_user.start_time * 1000), 'yyyy.MM.dd hh:mm:ss') }}</div>
                </div>
              </el-timeline-item>
            </el-timeline>
          </div>
          <div class="the-title">答题异常监控</div>
          <div class="abnormal-monitoring">
            <div class="mon-item" style="margin-bottom: 5px;">
              是否作弊:
              <el-tag size="small" type="danger" style="color: #f56c6c !important;"
                v-if="drawerData.train_user.is_cheat === '1'">是</el-tag>
              <el-tag size="small" v-else>否</el-tag>
            </div>
            <div class="mon-item-wrap">
              <div class="mon-item" :class="abnormalSearch.act_type === '' ? 'active-mon-item' : ''"
                @click="abnormalChangeSearch('')">
                全部异常行为:
                <span>
                  共计{{ Object.values(abnormalDataObj)?.reduce((pre, cur) => pre += Number(cur), 0) -
                    Number(abnormalDataObj["0"]) }}次
                </span>
              </div>
              <div class="mon-item" :class="abnormalSearch.act_type === '1' ? 'active-mon-item' : ''"
                @click="abnormalChangeSearch('1')">切屏次数：<span>{{ abnormalDataObj["1"] || '0' }}次</span></div>
              <div class="mon-item" :class="abnormalSearch.act_type === '2' ? 'active-mon-item' : ''"
                @click="abnormalChangeSearch('2')">复制粘贴:<span>{{ abnormalDataObj["2"] || '0' }}次</span></div>
              <div class="mon-item" :class="abnormalSearch.act_type === '3' ? 'active-mon-item' : ''"
                @click="abnormalChangeSearch('3')">人脸识别:<span>未识别到人脸{{ abnormalDataObj["3"] || '0' }}次</span></div>
              <div class="mon-item" :class="abnormalSearch.act_type === '4' ? 'active-mon-item' : ''"
                @click="abnormalChangeSearch('4')">多人识别:<span>{{ abnormalDataObj["4"] || '0' }}次</span></div>
              <div class="mon-item" :class="abnormalSearch.act_type === '5' ? 'active-mon-item' : ''"
                @click="abnormalChangeSearch('5')">换人识别:<span>{{ abnormalDataObj["5"] || '0' }}次</span></div>
            </div>
            <div class="mon-item">异常识别:</div>
            <div class="abnormal-imgs" style="margin-top: 20px;">
              <div class="img-item" v-for="item in abnormalPictureList" :key="item.id">
                <img :src="item.img_url" alt="">
              </div>
            </div>
            <MyPagin :pagination="abnormalPagination" @currentChange="abnormalCurChange"
              @sizeChange="abnormalSizeChange" />
          </div>
          <div class="the-title">答题摄像头画面抓取截图(除异常截图外)</div>
          <div class="normal-imgs">
            <div class="img-item" v-for="item in normalPictureList" :key="item.id">
              <img :src="item.img_url" alt="">
            </div>
          </div>
          <MyPagin :pagination="normalPagination" @currentChange="normalCurChange" @sizeChange="normalSizeChange" />
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance, onBeforeUnmount, nextTick } from 'vue';
import { MessageBox } from "element-ui"
import sha256 from "@/assets/sha256/sha256.js";
import utils from "@/utils/tools.js";
import MyPagin from "@/components/myPagin.vue";
import "aliyun-webrtc-sdk";
import Cookies from "js-cookie";
// 相当于this
const instance = getCurrentInstance().proxy;


// 考试信息
const examInfo = reactive({
  examName: "",
  examTime: "",
  examId: "",
  // 倒计时
  time_countdown: 0
});
function getExamInfo() {
  return new Promise((resolve, reject) => {
    instance.api.dataset.trainDataDetail({
      id: examInfo.examId
    }).then((res) => {
      liveRoomInfo.roomId = res?.live_room?.room_key || res.live_room.id;
      liveRoomInfo.liveDetailID = res.live_room.id;
      examInfo.time_countdown = Date.now() + Number(res.time_countdown) * 1000;
      getLiveRoomDetail();
      console.log("考试信息", res);
      resolve();
    }).catch((e) => {
      reject(e);
    });
  });
}

// 阿里音视频实例
const aliWebrtc = ref(null);
// 初始化音视频
function initWebRTC() {
  return new Promise((resolve, reject) => {
    /* eslint-disable-next-line */
    aliWebrtc.value = new AliRtcEngine();
    aliWebrtc.value.isSupport().then(() => {
      // 初始化音视频监听
      addWebRTCEvent();
      resolve();
    }).catch((e) => {
      reject(e);
    });
  });
}
// 添加事件监听
function addWebRTCEvent() {
  // 加入房间
  aliWebrtc.value.on("onJoin", () => {
    updateUserList();
  });
  // 离开房间
  aliWebrtc.value.on("onLeave", (publisher) => {
    unSubscribe(publisher.userId);
    updateUserList();
  });
  // 远程检测到推流
  aliWebrtc.value.on("onPublisher", async (publisher) => {
    await updateUserList();
    subscribe(publisher.userId, 2);
  });
}


// 直播间信息
const liveRoomInfo = reactive({
  // 房间id/频道id（阿里用的）
  roomId: "",
  // 进入直播间人的昵称
  nickname: "",
  // 进入直播间人的id
  userId: "",
  // 进入直播间人的记录id
  recordId: "",
  // 是否是主持人
  isHost: false,
  // 直播标题
  liveTitle: "",
  // 房间id（平台用的）
  liveDetailID:""
});
// 获取直播间详情
function getLiveRoomDetail() {
  return new Promise((resolve, reject) => {
    instance.api.live.getLiveRoomDetail({
      live_room_id: liveRoomInfo.liveDetailID
    }).then(res => {
      liveRoomInfo.isHost = res?.user?.is_manager === "1" ? true : false;
      liveRoomInfo.nickname = res?.user?.nickname || res?.user?.realname;
      liveRoomInfo.userId = res?.user?.id;
      liveRoomInfo.liveTitle = res?.info?.name;
      // 加入房间
      joineroom();
      resolve();
    }).catch(e => {
      reject(e);
    });
  });
}


const socket = ref(null);
let closeSocket = false;
// 初始化websocket
function initWebSocket() {
  return new Promise((resolve) => {
    const token = Cookies.get(instance.constant.tokenName);
    let heartbeatTimer=null;
    socket.value = new WebSocket(`${instance.constant.WSS}?token=${token}`);
    socket.value.onopen = () => {
      console.log("%cwebsocket打开", "color:lightpink");
      heartbeatTimer=setInterval(()=>{
        socket.value.send(JSON.stringify({data:"0",message:"0"}));
      },5000);
    }
    socket.value.onclose = () => {
      clearInterval(heartbeatTimer);
      if (!closeSocket) {
        let timer = null;
        timer = setTimeout(() => {
          initWebSocket();
          clearTimeout(timer);
        }, 1000);
      }
    }
    resolve();
  });
}


// 初始化所有信息
function init() {
  examInfo.examName = instance.$route.query.examName;
  examInfo.examId = instance.$route.query.examId;
  Promise.all([getExamInfo(), initWebRTC(), initWebSocket()]).then(async () => {
    console.log("初始化成功");
    window.addEventListener("mouseup", voiceReminderCancel);
  }).catch(e => {
    console.error("初始化失败", e);
  });
}
init();

// tabs
const activeTab = ref("1");
function changeTab(tab) {
  activeTab.value = tab;
  updateUserList();
}

// 用户列表数据
const userList = ref([]);
// 更新用户列表
function updateUserList() {
  return new Promise((resolve, reject) => {
    // instance.api.live.getLiveRoomUserList({
    //   live_room_id: liveRoomInfo.roomId
    // }).then(res => {
    //   userList.value = res.list || [];
    //   resolve();
    // }).catch(() => {
    //   reject();
    // });
    instance.api.dataset.trainUserList({
      train_id: examInfo.examId,
      status: activeTab.value
    }).then(res => {
      console.log("截屏列表", res);
      userList.value = res.list || [];
      resolve();
    }).catch(e => {
      reject(e);
    });
  });
}
// 循环请求
let timer = null;
function recurrentRequest() {
  timer = setInterval(() => {
    updateUserList();
  }, 30000);
}

// 加入房间
function joineroom() {
  // 获取频道鉴权令牌参数 为了防止被盗用建议该方法在服务端获取
  const authInfo = generateAliRtcAuthInfo();
  // 加入房间（请求平台）
  instance.api.live.enterLiveRoom({
    live_room_id: liveRoomInfo.liveDetailID
  }).then(res => {
    liveRoomInfo.recordId = res.live_room_user.id;
    updateUserList();
    recurrentRequest();
    // 加入房间（请求阿里）
    aliWebrtc.value.joinChannel(authInfo, liveRoomInfo.nickname);
  }).catch(e => {
    console.error("加入直播间失败", e);
    instance.$Message.error("进入直播间失败");
  });
}
// 离开房间
function leaveroom() {
  instance.api.live.leaveLiveRoom({
    live_room_id: liveRoomInfo.liveDetailID,
    live_room_user_id: liveRoomInfo.recordId
  }).then(() => {
    // 销毁会自动退出房间的
    aliWebrtc.value.dispose();
  });
}
// 获取用户鉴权信息
function generateAliRtcAuthInfo() {
  const appId = "buotssyu"; // 修改为自己的appid 该方案仅为开发测试使用，正式上线需要使用服务端的AppServer
  const key = "da9e361834bc628ce87c89e171db6752";     // 修改为自己的key 该方案仅为开发测试使用，正式上线需要使用服务端的AppServer
  const userId = liveRoomInfo.userId;
  const timestamp = parseInt(new Date().getTime() / 1000 + 48 * 60 * 60);
  const nonce = 'AK-' + timestamp;
  const token = sha256(appId + key + liveRoomInfo.roomId + userId + nonce + timestamp);
  const res = {
    appid: appId,
    userid: userId,
    timestamp: timestamp,
    nonce: nonce,
    token: token,
    gslb: ["https://rgslb.rtc.aliyuncs.com"],
    channel: liveRoomInfo.roomId
  };
  return res;
}


// 订阅
function subscribe(userId, type) {
  // !说明type类型：1-订阅远端屏幕共享流 2-视频实时流（无音频） 3-订阅远端视频流
  if (!userId) return;
  aliWebrtc.value.configRemoteCameraTrack(userId, true, true);
  aliWebrtc.value.configRemoteScreenTrack(userId, true);
  // 如果是2不需要订阅音频流
  if (type === 2) {
    aliWebrtc.value.configRemoteAudio(userId, false);
  } else {
    aliWebrtc.value.configRemoteAudio(userId, true);
  }
  aliWebrtc.value.subscribe(userId).then(() => {
    nextTick(() => {
      if (type === 1) {
        aliWebrtc.value.setDisplayRemoteVideo(userId, window.document.getElementById("remoteCameraVideo"), 1);
      } else if (type === 2) {
        aliWebrtc.value.setDisplayRemoteVideo(userId, window.document.getElementById(userId), 1);
      } else if (type === 3) {
        aliWebrtc.value.setDisplayRemoteVideo(userId, window.document.getElementById("remoteScreenVideo"), 2);
      }
    });
  });
}
// 取消订阅
function unSubscribe(userId) {
  if (!userId) return;
  aliWebrtc.value.configRemoteAudio(userId, false);
  aliWebrtc.value.configRemoteCameraTrack(userId, false, false);
}


// 考试详情抽屉
const drawerShow = ref(false);
function drawerClose() {
  subscribe(userDetail.value.id, 2);
  abnormalDataObj.value = {
    "0": "0", // 正常
    "1": "0", // 切屏
    "2": "0", // 复制
    "3": "0", // 未识别到人
    "4": "0", // 识别到多人
    "5": "0", // 换人
    "6": "0", // 打电话
    "7": "0", // 戴耳机
    "8": "0"  // 屏幕聊天
  }
}
// 详情
const userDetail = ref({});
const drawerData = reactive({
  train_user: {},
  user: {},
  train_user_id: "",
  // 班级列表
  user_group_list: []
});
// 打开抽屉
function viewDetail(item) {
  drawerData.train_user_id = item.id;
  userDetail.value = item.user;
  subscribe(userDetail.value.id, 1);
  subscribe(userDetail.value.id, 3);
  instance.api.dataset.trainUserDetail({
    train_user_id: item.id
  }).then(res => {
    drawerData.user = res.user || {};
    drawerData.train_user = res.train_user || {};
    drawerData.user_group_list = res.user_group_list || [];
  });
  getNormalPictureList();
  getAbnormalPictureList();
  drawerShow.value = true;
}
// 正常的图片列表
const normalPictureList = ref([]);
const normalPagination = reactive({
  page: 1,
  pageSize: 10,
  total: 0
});
// 获取正常图片列表
function getNormalPictureList() {
  instance.api.live.monitorUserDetail({
    live_room_id: liveRoomInfo.liveDetailID,
    user_id: userDetail.value.id,
    is_act_type_error: "0",
    type: "1",
    page: normalPagination.page,
    page_size: normalPagination.pageSize
  }).then(res => {
    normalPagination.total = Number(res.count);
    normalPictureList.value = res.list || [];
    // console.log("正常图片列表", res);
  });
}
function normalCurChange(page) {
  normalPagination.page = page;
  getNormalPictureList();
}
function normalSizeChange(size) {
  normalPagination.pageSize = size;
  getNormalPictureList();
}

// 异常的图片列表
const abnormalPictureList = ref([]);
const abnormalPagination = reactive({
  page: 1,
  pageSize: 10,
  total: 0
});
const abnormalDataObj = ref({
  "0": "0", // 正常
  "1": "0", // 切屏
  "2": "0", // 复制
  "3": "0", // 未识别到人
  "4": "0", // 识别到多人
  "5": "0", // 换人
  "6": "0", // 打电话
  "7": "0", // 戴耳机
  "8": "0"  // 屏幕聊天
});
const abnormalSearch = reactive({
  act_type: ""
});
function getAbnormalPictureList() {
  instance.api.live.monitorUserDetail({
    live_room_id: liveRoomInfo.liveDetailID,
    user_id: userDetail.value.id,
    // 异常类型
    act_type: abnormalSearch.act_type,
    // 正常还是异常图片
    is_act_type_error: "1",
    page: abnormalPagination.page,
    page_size: abnormalPagination.pageSize
  }).then(res => {
    abnormalPagination.total = Number(res.count);
    // 整理统计数据
    if (!Array.isArray(res.act_type_monitor_count_list)) {
      Object.keys(res.act_type_monitor_count_list).forEach(key => {
        abnormalDataObj.value[key] = res.act_type_monitor_count_list[key];
      });
    }
    // 图片列表赋值
    abnormalPictureList.value = res.list || [];
  });
}
function abnormalCurChange(page) {
  abnormalPagination.page = page;
  getAbnormalPictureList();
}
function abnormalSizeChange(size) {
  abnormalPagination.pageSize = size;
  getAbnormalPictureList();
}
// 异常行为图片的筛选
function abnormalChangeSearch(type) {
  abnormalSearch.act_type = type;
  abnormalPagination.page = 1;
  getAbnormalPictureList();
}



// 消息提醒
function messageReminder() {
  MessageBox.prompt('请输入提醒内容', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
    inputValue: "不要作弊，认真考试"
  }).then((res) => {
    socket.value.send(JSON.stringify({ action: "monitorNoticeUsers", live_room_id: liveRoomInfo.liveDetailID, to_user_id: userDetail.value.id, message: res.value }));
  });
}

// 语音提醒
function voiceReminder(type) {
  // type-1:个人提醒 2:全体语音提醒
  aliWebrtc.value.configLocalAudioPublish = true;
  aliWebrtc.value.configLocalCameraPublish = false;
  aliWebrtc.value.publish().then(() => {
    if (type === 1) {
      socket.value.send(JSON.stringify({ action: "liveRoomAudioUnicast", to_user_id: drawerData?.user?.id, live_room_id: liveRoomInfo.liveDetailID }));
    } else if (type === 2) {
      socket.value.send(JSON.stringify({ action: "liveRoomAudioBroadcast", live_room_id: liveRoomInfo.liveDetailID }));
    }
  });
}
// 取消语音提醒
function voiceReminderCancel() {
  aliWebrtc.value.unPublish();
}



// 标记作弊
function markCheating() {
  instance.api.dataset.markCheating({
    train_id: examInfo.examId,
    train_user_id: drawerData.train_user_id
  }).then(() => {
    socket.value.send(JSON.stringify({ action: "examMarkCheat", to_user_id: drawerData?.user?.id }));
    instance.$Message.success("已标记该学生作弊");
    drawerData.train_user.is_cheat = "1";
  });
}

// 强制交卷
function compulsorySubmissionPapers(trainUserId) {
  socket.value.send(JSON.stringify({ action: "examForceSubmit", to_user_id: trainUserId }));
  instance.api.dataset.compulsorySubmissionPapers({
    train_id: examInfo.examId,
    train_user_id: trainUserId
  });
}

// 截屏和视频切换
const listShowStatus = ref(false);
function screenshotVideoSwitch(value) {
  if (value) {
    userList.value.forEach(item => {
      subscribe(item.user_id, 2);
    });
  }
}

function fullscreen() {
  utils.fullscreen(document.getElementById("remoteScreenVideo"));
}

onBeforeUnmount(() => {
  closeSocket = true;
  socket.value.close();
  clearInterval(timer);
  leaveroom();
  window.removeEventListener("mouseup", voiceReminderCancel);
});

</script>

<style lang="scss" scoped>
.exam {
  padding: 20px;
  box-sizing: border-box;

  .exam-head {
    background-color: white;
    padding: 10px 20px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: bold;

    .exam-name {
      max-width: 50%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .exam-right {
      display: flex;
      align-items: center;

      .exam-time {
        flex-shrink: 0;
        display: flex;
        align-items: center;

        ::v-deep .el-statistic {
          width: auto;

          .con {
            .number {
              font-size: 20px;
              color: #5578F6;
            }
          }
        }
      }

      .yy-btn {
        background-color: #F8F8F9;
        padding: 16px 23px;
        border-radius: 4px;
        margin-left: 40px;
        display: flex;
        align-items: center;


        .el-button {
          margin-left: 10px;
        }

        .quanti-yuyin {
          &:active {
            background-color: rgba(51, 51, 51, 0.704) !important;
          }
        }
      }
    }
  }

  .exam-main {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    margin-top: 10px;

    .main-top {
      display: flex;
      justify-content: space-between;

      .tabs {
        display: flex;
        align-items: center;

        .tab {
          font-size: 16px;
          padding: 5px;
          margin-right: 10px;
          cursor: pointer;
        }

        .tab-active {
          font-weight: bold;
          color: white;
          background-color: #2d8cf0;
          border-radius: 4px;
        }
      }

    }

    .videos {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;

      .video-item {
        width: 284px;
        border-radius: 4px;
        overflow: hidden;
        margin-right: 20px;
        margin-bottom: 20px;

        .user-video-wrap {
          width: 100%;
          height: 160px;
          position: relative;

          >video {
            width: 100%;
            height: 100%;
            background-color: black;
          }

          >img {
            background-color: black;
            display: block;
            width: 100%;
            height: 160px;
            object-fit: contain;
          }

          .abnormal {
            width: 100%;
            height: 36px;
            background: #F13326;
            opacity: 0.9;
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: white;
            padding: 0 10px;

            .el-icon-warning {
              margin-right: 5px;
            }
          }
        }

        .user-info {
          padding: 10px;
          background-color: #F8F8F9;

          .user-name {
            font-size: 16px;
          }

          .user-btns {
            display: flex;
            margin-top: 10px;
            justify-content: flex-end;
          }
        }
      }
    }
  }


  .drawer-title-text {
    display: flex;

    .yy-btn-item {
      display: flex;
      align-items: center;
    }

    .yuyin-btn {
      &:active {
        background-color: #4a7affaa !important;
      }
    }

    .flex-btn {
      display: flex;
      align-items: center;

      >img {
        display: block;
        height: 14px;
        margin-left: 5px;
      }
    }
  }

  .drawer-title-btns {
    display: flex;
    font-size: 16px;
    align-items: center;

    >div {
      margin-right: 20px;
    }
  }

  .detail-drawer {
    .user-basic-info {
      padding: 20px;
      background: #F6F7FA;
      border-radius: 4px;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 30px;

      .avatar {
        width: 68px;
        height: 68px;
        border-radius: 50%;
        overflow: hidden;

        >img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .id-class-phone {
        margin-left: 10px;

        .basic-user-name {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }

        .spans {
          font-size: 14px;
          font-family: Microsoft YaHei;
          color: #666666;
          margin-top: 10px;

          >span {
            margin-right: 30px;
          }
        }
      }
    }

    .the-title {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      position: relative;
      padding-left: 10px;
      margin-bottom: 8px;

      &::before {
        content: "";
        width: 4px;
        height: 16px;
        background: #4A79FF;
        position: absolute;
        left: 0;
        top: 4px;
      }
    }

    .detail-video {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      margin-bottom: 30px;

      .video-wrap {
        width: 50%;
        flex-grow: 1;
        height: 220px;
        position: relative;
        border-radius: 4px;
        overflow: hidden;

        >video {
          width: 100%;
          height: 100%;
          background-color: black;

        }

        .tips {
          width: 100%;
          height: 54px;
          background: #333333;
          opacity: 0.9;
          position: absolute;
          bottom: 0;
          left: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
    }

    .answer-record {
      background: #F6F7FA;
      border-radius: 4px;
      padding: 20px;
      padding-bottom: 0;
      margin-bottom: 30px;

      .timeline-item-flex {
        display: flex;
        align-items: center;

        .tit {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          width: 60px;
        }

        .cont {
          font-size: 14px;
          color: #666666;
          margin-left: 20px;
        }
      }
    }

    .abnormal-monitoring {
      background: #F6F7FA;
      border-radius: 4px;
      padding: 20px;
      margin-bottom: 30px;


      .mon-item-wrap {
        display: flex;
        flex-wrap: wrap;
      }

      .mon-item {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        cursor: pointer;
        margin-right: 10px;
        border-radius: 2px;
        padding: 3px 5px;

        >span {
          font-weight: bold;
        }
      }

      .active-mon-item {
        background-color: #4A79FF;
        color: white;
        border-radius: 2px;
        padding: 3px 5px;
      }
    }

    .abnormal-imgs {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 20px;

      .img-item {
        background-color: black;
        width: 145px;
        height: 145px;
        border-radius: 4px;
        overflow: hidden;

        >img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .normal-imgs {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 20px;

      .img-item {
        background-color: black;
        width: 145px;
        height: 145px;
        border-radius: 4px;
        overflow: hidden;

        >img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}</style>
